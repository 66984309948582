import loadJS from '../utils/load-js.js';

const key = '6Lc76qoUAAAAALk0aYR500zOZVG1BicNl3GTb-Hu';
const delay = 90000;

let intervalID;

const execute = (node, action) => {
    const no = Math.floor(Math.random() * 999999) + 100000;

    window.grecaptcha.execute(key, { action: action + no }).then((token) => {
        node.value = token;
    });

    if (!intervalID) {
        intervalID = setInterval(() => { execute(node, action); }, delay);
    }
};

const inititate = (node) => {
    if (intervalID) {
        return;
    }

    const action = node.dataset.action || '';

    loadJS('https://www.google.com/recaptcha/api.js?render=' + key).then(() => {
        if (!window.grecaptcha) {
            return;
        }

        window.grecaptcha.ready(function () {
            execute(node, action);
        });
    });
};

export default async function inititateRecaptcha (node) {

    if ('IntersectionObserver' in window) {
        const form = node.closest('form');

        const io = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    inititate(node);
                    io.unobserve(entry.target);
                }
            });
        }, {});

        io.observe(form);
    } else if (window.requestIdleCallback) {
        window.requestIdleCallback(() => { inititate(node); });
    } else {
        inititate(node);
    }
};
