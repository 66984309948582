
export async function getAccordion () {
    const { default: inititateAccordion } = await import(/* webpackChunkName: "accordion" */ './accordion/accordion');

    return inititateAccordion;
}

export async function getTabs () {
    const { default: inititateTabs } = await import(/* webpackChunkName: "tabs" */ './tabs/tabs');

    return inititateTabs;
}

export async function getFeedbackSwiper () {
    const { default: inititateFeedbackSwiper } = await import(/* webpackChunkName: "feedback" */ './feedback/feedback');

    return inititateFeedbackSwiper;
}

export async function getAutocomplete () {
    const { default: inititateAutocomplete } = await import(/* webpackChunkName: "autocomplete" */ './autocomplete/autocomplete');

    return inititateAutocomplete;
}

export async function getMaps () {
    const { default: inititateMap } = await import(/* webpackChunkName: "map" */ './map/map');

    return inititateMap;
}

export async function getJobShare () {
    const { default: inititateJobShare } = await import(/* webpackChunkName: "job-share" */ './job-share/job-share');

    return inititateJobShare;
}

export async function inititatePopup () {
    const { default: inititatePopup } = await import(/* webpackChunkName: "popup" */ './popup/popup');

    inititatePopup();
}
