// https://github.com/webpack/webpack/issues/8656#issuecomment-456001556

import './polyfills/polyfills';

import { inititateRecaptcha } from './core/';

import {
    getAccordion,
    getFeedbackSwiper,
    getAutocomplete,
    getMaps,
    getJobShare,
    inititatePopup,
    getTabs,
} from './components/components';

if (module.hot) {
    module.hot.accept();
}

const subscribeGTM = () => {
    window.dataLayer = window.dataLayer || [];

    const getJobID = () => {
        const jobID = (document.querySelector('input[name="job_id"]') || {}).value;

        return jobID ? parseInt(jobID) : '';
    };

    const getCategory = () => {
        const node = document.querySelector('meta[itemprop="occupationalCategory"]');

        if (!node) {
            return '';
        }

        return node.getAttribute('content') || '';
    };

    document.querySelectorAll('.gtm-share-click').forEach((node) => {
        node.addEventListener('click', (e) => {
            const dataset = node.dataset || {};
            window.dataLayer.push({
                event: 'shareClick',
                shareLabel: dataset.label || '',
            });
        });
    });

    document.querySelectorAll('.gtm-click').forEach((node) => {
        node.addEventListener('click', (e) => {
            const dataset = node.dataset || {};
            window.dataLayer.push({
                event: dataset.event || '',
                label: dataset.label || '',
            });
        });
    });

    const storage = window.sessionStorage;
    const opened = document.querySelector('[name="gtm-position-opened"]');
    const started = document.querySelector('[name="gtm-application-started"]');
    const submitted = document.querySelector('[name="gtm-application-submitted"]');

    const jobID = getJobID();

    const setPositionOpened = () => {
        window.dataLayer.push({
            event: 'offerOpened',
            job_id: jobID,
            category: getCategory(),
        });
        storage.setItem(`position-${jobID}`, 'opened');
    };

    const setApplicationStarted = () => {
        window.dataLayer.push({
            event: 'applicationStarted',
            job_id: jobID,
            category: getCategory(),
        });
        storage.setItem(`position-${jobID}`, 'started');
    };

    const setApplicationSubmitted = () => {
        window.dataLayer.push({
            event: 'applicationSuccess',
            job_id: jobID,
            category: getCategory(),
        });
        storage.setItem(`position-${jobID}`, 'submitted');
    };

    const handleInputChange = (e) => {
        if (e.target.value) {
            setApplicationStarted();
            e.target.removeEventListener('input', handleInputChange);
        }
    };

    if (opened) {
        if (!storage.getItem(`position-${jobID}`)) {
            setPositionOpened();
        }
    }

    if (started && storage.getItem(`position-${jobID}`) === 'opened') {
        const node = document.querySelector('[name="form-position"] #form-name') || {};

        if (node) {
            if (node.value) {
                setApplicationStarted();
            } else {
                node.addEventListener('input', handleInputChange);
            }
        }
    }

    if (submitted && storage.getItem(`position-${jobID}`) === 'started') {
        setApplicationSubmitted();
    }
};

// const saveCookies = () => {
//     const P2 = (document.getElementById('cookies-warning-2') || {}).checked || false;
//     const P3 = (document.getElementById('cookies-warning-3') || {}).checked || false;
//     const P4 = (document.getElementById('cookies-warning-4') || {}).checked || false;

//     if (P2 && P3 && P4) {
//         localStorage.setItem('biuro-cookies-P2', P2);
//         localStorage.setItem('biuro-cookies-P3', P3);
//         localStorage.setItem('biuro-cookies-P4', P4);
//     } else {
//         sessionStorage.setItem('biuro-cookies-P2', P2);
//         sessionStorage.setItem('biuro-cookies-P3', P3);
//         sessionStorage.setItem('biuro-cookies-P4', P4);
//     }
// };

const enableCookies = () => {
    // const P2 =
    //     localStorage.getItem('biuro-cookies-P2') === 'true' || sessionStorage.getItem('biuro-cookies-P2') === 'true';
    // const P3 =
    //     localStorage.getItem('biuro-cookies-P3') === 'true' || sessionStorage.getItem('biuro-cookies-P3') === 'true';
    // const P4 =
    //     localStorage.getItem('biuro-cookies-P4') === 'true' || sessionStorage.getItem('biuro-cookies-P4') === 'true';

    // if (location.hostname.startsWith('dev.') || location.hostname.startsWith('staging.')) {
    if (location.hostname.startsWith('dev.')) {
        return;
    }

    fetch('/wp-json/api/v1/cookies-config')
        .then((t) => t.json())
        .then((data) => {
            window.dataLayer = window.dataLayer || [];

            // window.dataLayer.unshift({ P2 }, { P3 }, { P4 });

            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', data.google);

            subscribeGTM();

            // if (data.google) {
            //     enableCookiesGoogle(data.google);
            // }

            // if (data.facebook) {
            //     enableCookiesFacebook(data.facebook);
            // }

            // if (data.linkedIn) {
            //     enableCookiesLinkedIn(data.linkedIn);
            // }
        });
};

((global) => {
    enableCookies();
})(window);

// ((global) => {
//     const container = document.getElementById('cookie-warning');
//     const options = document.getElementById('cookie-options');
//     const btnAgree = document.getElementById('cookie-agree');
//     const btnSettings = document.getElementById('cookie-settings');
//     const isAnswered =
//         !!localStorage.getItem('biuro-cookies-P2') || !!sessionStorage.getItem('biuro-cookies-P2') || false;

//     if (!container || !options || !btnAgree || !btnSettings) {
//         return;
//     }

//     if (isAnswered) {
//         enableCookies();
//         return;
//     }

//     container.style.display = 'block';

//     btnAgree.addEventListener('click', () => {
//         saveCookies();

//         enableCookies();

//         container.style.display = 'none';
//     });

//     btnSettings.addEventListener('click', () => {
//         btnSettings.style.display = 'none';
//         options.style.display = 'block';
//     });
// })(window);

((global) => {
    const node = document.querySelector('.c-nav--lang');

    if (!node) {
        return;
    }

    node.addEventListener('click', (e) => {
        e.currentTarget.classList.toggle('c-nav--lang--is-open');
    });
})(window);

((global) => {
    document.querySelectorAll('.js-toggle--nav').forEach((node) => {
        node.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('is-nav--open');
        });
    });

    document.querySelectorAll('.js-toggle--aside').forEach((node) => {
        node.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.remove('is-aside--open');
            document.body.classList.remove('is-aside--open-additional');
            document.body.classList.remove('is-aside--open-city');
        });
    });

    document.querySelectorAll('.js-toggle--aside-city').forEach((node) => {
        node.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.add('is-aside--open');
            document.body.classList.remove('is-aside--open-additional');
            document.body.classList.add('is-aside--open-city');
        });
    });

    document.querySelectorAll('.js-toggle--aside--additional').forEach((node) => {
        node.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.toggle('is-aside--open');
            document.body.classList.remove('is-aside--open-city');
            document.body.classList.add('is-aside--open-additional');
        });
    });
})(window);

document.querySelectorAll('.js-form--input-file').forEach((node) => {
    node.addEventListener('change', () => {
        const parent = node.parentNode;
        const name = node.value.split(/\\|\//).pop();
        const text = parent.querySelector('.js-form--input-file-text');

        if (text && name) {
            text.innerHTML = name;
        }
    });
});

document.querySelectorAll('.js-toggle--services').forEach((node) => {
    node.addEventListener('click', () => {
        node.parentNode.classList.toggle('c-services--is-open');
    });
});

document.querySelectorAll('.js-toggle--sections').forEach((node) => {
    node.addEventListener('click', () => {
        const parent = node.parentNode;

        if (parent.classList.contains('c-sections--is-open')) {
            parent.classList.toggle('c-sections--is-open');
        } else {
            document.querySelectorAll('.c-sections--is-open').forEach((node) => {
                node.classList.remove('c-sections--is-open');
            });
            parent.classList.toggle('c-sections--is-open');
        }
    });
});

document.querySelectorAll('.js-expand-jobs-section').forEach((node) => {
    node.addEventListener('click', (e) => {
        e.preventDefault();
        node.parentNode.classList.remove('c-jobs-section--is-closed');
        node.remove();
    });
});

const initiatePositionForm = () => {
    const button = document.querySelector('[name="s"][value="1"]');

    if (button) {
        button.click();
        return;
    }

    const input = document.querySelector('[name="name"]');

    if (input) {
        input.focus();
    }
};

if (document.querySelector('.js-inititate-position-form')) {
    document.querySelector('.js-inititate-position-form').addEventListener('click', initiatePositionForm);
}

if (document.querySelector('.js-focus-form')) {
    document.querySelector('.js-focus-form').addEventListener('click', () => {
        const input = document.querySelector('.c-form input:first-of-type');

        if (input) {
            input.focus();
        }
    });
}

if (document.querySelector('.js-modal--close')) {
    document.querySelector('.js-modal--close').addEventListener('click', () => {
        const modal = document.querySelector('.c-modal');

        if (modal) {
            modal.remove();
        }

        const node = document.querySelector('.c-form [autofocus]');

        if (node) {
            node.focus();
        }
    });
}

((global) => {
    const node = document.querySelector('.c-form [autofocus]');

    if (node) {
        node.focus();
    }

    if (location.hash) {
        const error =
            document.querySelector('.c-form--input-wrap--error input') ||
            document.querySelector('.c-form--checkbox--error');

        if (error) {
            error.focus();
            return;
        }

        const input = document.querySelector('.c-form [name="name"]');

        if (input) {
            input.focus();
        }
    }
})(window);

const submitContactsFrom = (form) => {
    if (form.querySelector('[type="submit"]')) {
        form.querySelector('[type="submit"]').classList.add('c-btn--disabled');
        form.querySelector('[type="submit"]').classList.add('c-btn--loading');
    }
};

document
    .querySelectorAll(
        '[name="form-position"], [name="form-employees"], [name="form-employers"], [name="form-recommend"], [name="form-newsletter"]',
    )
    .forEach((form) => {
        form.addEventListener('submit', () => {
            submitContactsFrom(form);
        });
    });

if (document.querySelector('#submit-recommend')) {
    document.querySelector('#submit-recommend').addEventListener('click', (e) => {
        e.preventDefault();

        const button = document.querySelector('[name="form-recommend"] [type="submit"]');

        if (!button) {
            return;
        }

        e.currentTarget.classList.add('c-btn--disabled');
        e.currentTarget.classList.add('c-btn--loading');

        button.click();
    });
}

if (document.querySelector('.js-submit-employees-form')) {
    document.querySelector('.js-submit-employees-form').addEventListener('click', (e) => {
        const button = document.querySelector('[name="form-employees"] [type="submit"]');

        if (!button) {
            return;
        }

        e.preventDefault();

        e.currentTarget.classList.add('c-btn--disabled');
        e.currentTarget.classList.add('c-btn--loading');

        button.click();
    });
}

if (document.querySelector('.js-accordion--header')) {
    getAccordion().then((inititateAccordion) => {
        inititateAccordion();
    });
}

if (document.querySelector('.js-tabs')) {
    getTabs().then((inititateTabs) => {
        inititateTabs();
    });
}

const inititateFeedbacks = (node) => {
    if (!node) {
        return;
    }

    const inititate = () => {
        getFeedbackSwiper().then((inititateFeedbackSwiper) => {
            inititateFeedbackSwiper();
        });
    };

    if (!window.IntersectionObserver) {
        inititate();
        return;
    }

    const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                inititate();
                io.unobserve(entry.target);
            }
        });
    }, {});

    io.observe(node);
};

inititateFeedbacks(document.querySelector('.c-feedbacks--inner'));

const inititateAutocompletes = () => {
    const inititate = () => {
        getAutocomplete().then((inititateAutocomplete) => {
            inititateAutocomplete();
        });
    };

    if (window.requestIdleCallback) {
        window.requestIdleCallback(inititate);
    } else {
        inititate();
    }
};

if (
    document.getElementById('search') ||
    document.getElementById('form-city') ||
    document.getElementById('recommend-form-city')
) {
    inititateAutocompletes();
}

if (
    document.getElementById('js-map--divisions') ||
    document.getElementById('js-map--cities') ||
    document.getElementById('js-map--regions')
) {
    getMaps().then((inititateMap) => {
        inititateMap();
    });
}

if (
    document.querySelector('.js-copy-to-clipboard') ||
    document.querySelector('.js-share-messenger') ||
    document.querySelector('.js-share-whatsapp') ||
    document.querySelector('.js-biuro-facebook')
) {
    getJobShare().then((inititateJobShare) => {
        inititateJobShare();
    });
}

inititatePopup();

if (document.querySelector('.js-collapse-row--toggle')) {
    document.querySelector('.js-collapse-row--toggle').addEventListener('click', (e) => {
        e.currentTarget.parentNode.classList.toggle('c-form--row--collapsed');
    });
}

const recaptcha = document.getElementById('g-recaptcha-response');

if (recaptcha) {
    inititateRecaptcha(recaptcha);
}

((global) => {
    const form = document.getElementById('search');

    if (!form) {
        return;
    }

    document.querySelectorAll('.js-search--focus').forEach((node) => {
        node.addEventListener('focus', () => {
            if (window.innerWidth < 960 && form.getBoundingClientRect().top > 80) {
                window.scroll(0, 210);
            }
        });
    });
})(window);

((global) => {
    const hiddenCSS = 'is-header--hidden';
    const header = document.querySelector('.l-header');
    const delay = 250;

    if (!header) {
        return true;
    }

    let dHeight = 0;
    let wHeight = 0;
    let wScrollCurrent = 0;
    let wScrollBefore = 0;
    let wScrollDiff = 0;

    const throttle = function (delay, fn) {
        var last, deferTimer;

        return function () {
            const context = this;
            const args = arguments;
            const now = new Date();

            if (last && now < last + delay) {
                clearTimeout(deferTimer);
                deferTimer = setTimeout(() => {
                    last = now;
                    fn.apply(context, args);
                }, delay);
            } else {
                last = now;
                fn.apply(context, args);
            }
        };
    };

    window.addEventListener(
        'scroll',
        throttle(delay, function () {
            dHeight = document.body.offsetHeight;
            wHeight = window.innerHeight;
            wScrollCurrent = window.pageYOffset;
            wScrollDiff = wScrollBefore - wScrollCurrent;

            if (wScrollCurrent <= 0) {
                header.classList.remove(hiddenCSS);
            } else if (wScrollDiff > 0 && header.classList.contains(hiddenCSS)) {
                header.classList.remove(hiddenCSS);
            } else if (wScrollDiff < 0) {
                if (wScrollCurrent + wHeight >= dHeight && header.classList.contains(hiddenCSS)) {
                    header.classList.remove(hiddenCSS);
                } else if (!document.body.classList.contains('is-nav--open')) {
                    header.classList.add(hiddenCSS);
                }
            }

            wScrollBefore = wScrollCurrent;
        }),
    );
})(window);

((global) => {
    // below js for sales page
    // Get all the links and calculate their positions
    const links = document.querySelectorAll('.scrool_bar a');

    if (links.length === 0) {
        return;
    }

    const positions = [];

    links.forEach((link) => {
        const target = document.querySelector(link.getAttribute('href'));
        const position = target.offsetTop;
        positions.push(position);
    });

    // Define the function
    const handleScrollAndLoad = () => {
        const scrollPosition = window.scrollY + 130;
        // Find the link to be active
        let activeLinkIndex = 0;
        for (let i = 0; i < positions.length; i++) {
            if (scrollPosition >= positions[i]) {
                activeLinkIndex = i;
            }
        }

        links.forEach((link) => link.classList.remove('active'));
        links[activeLinkIndex].classList.add('active');
    };

    window.addEventListener('scroll', handleScrollAndLoad);
    window.addEventListener('load', handleScrollAndLoad);

    document.querySelectorAll('.js-scroll-top').forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
            e.preventDefault();

            const selector = anchor.getAttribute('href');
            const node = document.querySelector(selector);

            if (!node) {
                return;
            }

            const top = node.offsetTop - 130;

            window.scrollTo({
                top: top,
                behavior: 'smooth',
            });
        });
    });

    const skills = document.querySelector('#releventSkillsRow');

    if (skills) {
        document.querySelectorAll('.js-sales-page--position').forEach((position) => {
            position.addEventListener('mouseover', (e) => {
                skills.classList.add(`hover-section--${position.dataset.section}`);
            });
            position.addEventListener('mouseout', (e) => {
                skills.classList.remove(`hover-section--${position.dataset.section}`);
            });
        });
    }
})(window);
